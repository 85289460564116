<template>
    <div v-if="$store.state.taskList && $store.state.taskList.btnVisible" class="task-bar">
        <div class="show-task-bar">
            <span class="btn" @click="visible = true">上传任务</span>
            <el-progress
                type="circle"
                :percentage="$store.state.taskList.uploading.length ? parseInt($store.state.taskList.uploading[0].percent * 100) : 100"
                :width="50"
                class="btn-progress"
            />
            <div class="close el-icon-circle-close" @click="$store.dispatch('setTaskList',{...$store.state.taskList,btnVisible: false})"></div>
        </div>
        <div :class="['task-drawer', visible ? 'task-drawer-show' : '']">
            <header>
                <div class="title">
                    上传任务(
                    {{
                        $store.state.taskList.taskList.length -
                            $store.state.taskList.uploading.length +
                            '/' +
                            $store.state.taskList.taskList.length
                    }}
                    )
                </div>
                <div class="close" @click="visible = false"><i class="el-icon-close" /></div>
            </header>
            <div class="task-list">
                <div v-for="(item, index) in $store.state.taskList.taskList" :key="index" class="task-item">
                    <el-row :gutter="20" type="flex" justify="space-between" align="middle">
                        <el-col :span="8">
                            <div class="task-name">{{ item.Key.substring(item.Key.indexOf('-') + 1) }}</div>
                        </el-col>
                        <el-col :span="16">
                            <el-progress :percentage="parseInt(item.percent * 100)" :color="item.percent == 1 ? '#67c23a' : '#409eff'"></el-progress>
                        </el-col>
                        <!-- <el-col :span="4">
                            <el-button v-if="item.percent < 1" type="text" size="mini" @click="cancelTask(item.id)">取消</el-button>
                        </el-col> -->
                    </el-row>
                </div>
            </div>
            <!-- <div v-else class="no-data">当前没有上传任务</div> -->
        </div>
    </div>
</template>

<script>
import Upload from '@/assets/js/upload';
export default {
    name: 'taskbar',
    data() {
        return {
            visible: false
        };
    },
    created() {},
    methods: {
        cancelTask(id) {
            Upload.cancelTask(id);
        }
    }
};
</script>
<style lang="scss" scoped>
.task-bar {
    .show-task-bar{
        position: fixed;
        top: 44px;
        right: 20px;
        z-index: 1000;
        .btn {
            position: absolute;
            top: 4px;
            background: #1f2428;
            right: 4px;
            color: #ffffff;
            width: 42px;
            height: 42px;
            padding: 5px;
            border-radius: 100%;
            display: flex;
            text-align: center;
            align-items: center;
            cursor: pointer;
            z-index: 1;
        }
        .btn-progress {
            
        }
        .close{
            font-size: 26px;
            position: absolute;
            bottom: -26px;
            left: 12px;
            color: #999;
            cursor: pointer;
            display: none;
        }
        &:hover{
            .close{
                display: block;
            }
        }
    }
    
    .task-drawer {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 2000;
        background: #ffffff;
        transition: all 0.3s ease-out;
        transform: translateY(100%);
        &.task-drawer-show {
            transform: translateY(0);
        }
        header {
            margin-bottom: 10px;
            padding: 0 20px;
            height: 38px;
            line-height: 38px;
            background: #f5f7fa;
            display: flex;
            justify-content: space-between;
            .close {
                cursor: pointer;
            }
        }
    }
    .task-list {
        padding: 0 20px 10px 20px;
        max-height: 50vh;
        overflow-y: auto;
        .task-item {
            padding: 5px 0;
            .task-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .no-data {
        text-align: center;
    }
}
</style>
