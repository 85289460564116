<template>
    <div class="page-footer">
        版权所有©{{ year }} 成都太合乐动科技有限公司.All right reserved.<a class="color-theme" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备14016442号-4</a>
    </div>
</template>
<script>
export default {
    name:"pageFooter",
    data(){
        return {
            year: new Date().getFullYear()
        };
    }
}
</script>

<style lang="scss" scoped>
.page-footer{
    background: #fff;
    padding: 16px 20px;
    color: $--color-text-regular;
    font-size: 12px;
    text-align: center;
}
</style>