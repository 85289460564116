<template>
    <div>
        <div v-if="song" class="page-player-bar">
            <div class="poster">
                <el-image :src="playerBg" :class="playing ? 'playing' : ''" ></el-image>
                <div class="player-btn" @click="!playing ? $player.play(song) : $player.pause()">
                    <span v-if="loading" class="el-icon-loading"></span>
                    <template v-else>
                        <span v-if="!playing" class="el-icon-video-play"></span>
                        <span v-else class="el-icon-video-pause"></span>
                    </template>
                </div>
            </div>
            <div class="info" ref="info">
                <div class="info-bar">
                    <div class="name">{{song.name}}</div>
                    <div class="time-bar">
                        <div class="time-rogress"><el-slider v-model="timeupdate" @change="changeCurrentTime" :format-tooltip="(v) => $utils.formatTime(v)"></el-slider></div>
                        <div class="time">{{durationFormat}}</div>
                    </div>
                </div>
                <el-button type="text" icon="el-icon-close" @click="close" size="mini">关闭</el-button>
            </div>
            
        </div>
    </div>
</template>

<script>
import playerBg from '@/assets/img/player-bg.png';
export default {
    data(){
        return{
            song: this.$player.getSong() || null,
            // width: this.$refs && this.$refs.info && this.$refs.info.clientWidth || 0,
            duration: 0,
            durationFormat: '00:00:00',
            playing: this.$player.getState() == "playing" ? true : false ,
            playerBg:playerBg,
            loading: false,
            timeupdate: 0
        }
    },
    mounted(){
        this.$player.on("error",() => {
            // this.$notify.error({
            //     title: '错误',
            //     message: '播放出错'
            // });
            
            if(this.$player.getSong()) this.$message.error('播放出错');
            this.$player.setSong();
            this.$player.setUrl();

            this.playing = false
            this.loading = false
        }).on("loadstart", () => {
            this.song = this.$player.getSong();
            // this.$nextTick(() => {
            //     this.width = this.$refs && this.$refs.info && this.$refs.info.clientWidth;
            // })
            this.playing = false
            this.loading = true
        }).on("playing", () => {
            this.durationFormat = this.$utils.formatTime(this.$player.getDuration());
            this.duration = this.$player.getDuration();
            this.playing = true
            this.loading = false
        }).on("pause" , () => {
            this.playing = false
            this.loading = false
        }).on("ended", () => {
            this.playing = false
            this.loading = false
        }).on("timeupdate", e => {
            this.timeupdate = parseFloat(this.$player.getCurrentTime() / this.duration * 100);
        })
    },
    methods:{
        changeCurrentTime(){
            if(this.duration > 0){
                this.$player.audio.currentTime = this.timeupdate * this.$player.getDuration() / 100;
            }
        },
        close(){
            this.$player.destroy();
            this.song = null;
        }
    },
}
</script>

<style lang="scss" scoped>
.page-player-bar{
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 0 100px 100px 0px;
    padding: 5px;
    z-index: 1000;
    overflow: hidden;
    transition: all 0.5s ease-out;
    left: 0;
    .poster{
        width: 40px;
        height: 40px;
        position: relative;
        border-radius: 100%;
        overflow: hidden;
        .el-image{
            width: 100%;
            height: 100%;
            vertical-align: middle;
            &.playing{
                animation: rotate 2.4s 0s linear infinite;
            }
        }
        .player-btn{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;    
            background: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            span{
                font-size: 24px;
                color: #ffffff;    
            }
        }
    }
    .info{
        margin:0 5px;
        overflow: hidden;
        white-space: nowrap;
        width: 0;
        transition: all 0.5s ease-out;
        display: flex;
        align-items: center;
        .info-bar{
            flex: 1;
            margin:0 10px 0 0;
            .time-bar{
                display: flex;
                align-items: center;
                font-size: 12px;
                .time-rogress{
                    flex: 1;
                    margin:0 20px 0 -10px;
                    padding-left: 10px;
                    ::v-deep .el-slider__runway{
                        margin: 10px;
                    }
                    ::v-deep .el-slider__bar{
                        margin-left: -8px;
                    }
                }
                
            }
        }
    }
    &:hover{
        .info{
            // display: block;
            width: 300px;
        }
    }
}
</style>