<template>
    <div class="page-sidebar">
        <div class="page-sidebar-box">
            <!-- <div class="sidebar-toggler">
                <span @click="isCollapse = !isCollapse">
                    <i v-if="!isCollapse" class="el-icon-s-fold"></i>
                    <i v-else class="el-icon-s-unfold"></i>
                </span>
            </div> -->
            <page-menu :menuWidth="menuWidth" :isCollapse="isCollapse"></page-menu>
        </div>
    </div>
</template>
<script>
import PageMenu from './menu';
export default {
    components:{PageMenu},
    data() {
        return {
            isCollapse: false,
            menuWidth: 220
        };
    },
    watch: {
        isCollapse(flag){
            this.menuWidth = !flag ? 225 : 64;
        }
    },
    mounted() {
        const _this = this;

        const _width = window.innerWidth || document.body.clientWidth;
        if( _width < 1280 ){
            this.isCollapse = true
        }

        window.onresize = ()=>{
            const winWidth = window.innerWidth || document.body.clientWidth;
            _this.isCollapse = winWidth < 1280 ? true : false;
        }
    }
};
</script>
<style lang="scss" scoped>
.page-sidebar {
    background: #161616;
    .page-sidebar-box {
        // .sidebar-toggler {
        //     text-align: right;
        //     span {
        //         display: inline-block;
        //         background: #242424;
        //         // color: $--color-font-gray;
        //         margin: 10px 16px;
        //         font-size: 18px;
        //         padding: 5px 7px;
        //         cursor: pointer;
        //     }
        // }
    }
}
</style>