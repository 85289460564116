<template>
    <div class="app-wrapper">
        <el-scrollbar>
            <page-header></page-header>
            <el-container :key="$route.path">
                <page-sidebar class="hidden-xs-only"></page-sidebar>
                <el-main class="page-main">
                    <div class="page-main-content">
                        <!-- <page-breadcrumb></page-breadcrumb> -->
                        <page-title></page-title>
                        <router-view tag="main" />
                    </div>
                    <page-footer class="hidden-xs-only"></page-footer>
                </el-main>
            </el-container>
        </el-scrollbar>
        <el-backtop></el-backtop>
        <player></player>
        <task-bar></task-bar>
    </div>
</template>

<script>
import PageHeader from "./components/pageHeader";
import PageSidebar from "./components/pageSidebar";
import PageFooter from "./pageFooter";
// import PageBreadcrumb from './pageBreadcrumb';
import PageTitle from './components/pageTitle';
import Player from '@/components/Player';
import taskBar from './Task';
export default {
    components: { PageSidebar, PageHeader, PageFooter, /*PageBreadcrumb*/PageTitle,Player, taskBar },
    data() {
        return {}
    },
};
</script>

<style lang="scss" scoped>
::v-deep .page-header{
    .page-header-content{
        width: auto;
        margin: 0 60px;
        @media screen and (max-width: $--screen-md) {
            margin: 0;
        }
    }
}
// .app-wrapper{
//     height: 100%;
//     .el-scrollbar{
//         height: 100%;
//     }
// }
.el-container{
    min-height: calc(100vh - #{$--header-height});
    // width: 100%;
    background: #f8f8f8;
    .page-main{
        padding: 0;
        .page-main-content{
            height: calc(100% - 50px);
            padding: 20px;
        }
        div[tag="main"]{
            // background: #ffffff;
            // padding: 20px;
        }
    }
    
}
</style>